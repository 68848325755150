// extracted by mini-css-extract-plugin
export var category_content_wrapper = "category-module--category_content_wrapper--E-qm9";
export var category_tab_button = "category-module--category_tab_button--AVWUb";
export var category_tab_wrapper = "category-module--category_tab_wrapper--6oxr+";
export var category_tile_column = "category-module--category_tile_column--sf+Q4";
export var category_tile_img = "category-module--category_tile_img--CDJwU";
export var category_tile_overlay = "category-module--category_tile_overlay--CYcUO";
export var category_tile_text = "category-module--category_tile_text--fxs6n";
export var category_tile_wrapper = "category-module--category_tile_wrapper--CoSla";
export var category_tiles = "category-module--category_tiles--WEmsi";
export var category_wordcloud_wrapper = "category-module--category_wordcloud_wrapper--CNSuS";