import React from "react"
import { graphql, Link } from "gatsby"
import { getImage, ImageDataLike, GatsbyImage } from "gatsby-plugin-image"
import { RouteComponentProps } from "@reach/router"
import { Container, Row, Col, Button } from "react-bootstrap"

import Layout from "../components/layout"

import * as styles from "../styles/category.module.css"

interface CategoryQueryProps extends RouteComponentProps {
  data: {
    strapiCategory: {
      slug: string
      name: string
      background: {
        localFile: ImageDataLike
      }
      artists: {
        slug: string
        name: string
        xlntManaged: boolean
        image: {
          localFile: ImageDataLike
        }
      }[]
    }
  }
  pageContext: any
}

export default function CategoryPage({ data, pageContext, location }: CategoryQueryProps) {
  const { strapiCategory } = data;
  const defaultLang = "hu";
  strapiCategory.artists = strapiCategory.artists.filter(elem => elem.xlntManaged === true);

  function createLink(to: string) {
    if (pageContext.lang === defaultLang) {
      return `/${to}`;
    }
    else {
      return `/${pageContext.lang}/${to}`
    }
  }
  return (
    <Layout lang={pageContext.lang} pageMeta={{ title: strapiCategory.name }} background={strapiCategory.background.localFile} location={location} defaultLang={defaultLang}>
      <Container className={`${styles.category_content_wrapper}`}>
        <Row xs={2} xl={3} className={`${styles.category_tiles}`}>  
            {strapiCategory.artists && strapiCategory.artists.map(({ name, image, slug }) => {
              const artist_img = getImage(image.localFile)!
              return (
                <Col className={styles.category_tile_column} key={slug}>
                  <Link to={createLink(`${strapiCategory.slug}/${slug}`)} className={styles.category_tile_wrapper}>
                    <GatsbyImage image={artist_img} alt={name} className={styles.category_tile_img} />
                    <span className={styles.category_tile_overlay}>
                      <p className={styles.category_tile_text}>
                        {name}
                      </p>
                    </span>
                  </Link>
                </Col>
              )
            })}
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
query CategoryQuery($slug: String!) {
    strapiCategory(slug: {eq: $slug}) {
      slug
      name
      background {
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
          }
        }
      }
      artists {
        slug
        name
        xlntManaged
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                aspectRatio: 1
                transformOptions: {cropFocus: CENTER}
              )
            }
          }
        }
      }
    }
  }
`